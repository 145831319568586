import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./kalashproducts/Context/Context";

// Components
// import ScrollToTopButton from "./kalashproducts/Components/ScrollToTop";

import HeaderAlpha from "./kalashproducts/Components/HeaderMain";
import FooterAlpha from "./kalashproducts/Components/FooterMain";
import NotFoundPage from "./kalashproducts/Pages/404";
import Loader from "./kalashproducts/Components/loader/Loader";

const Header = React.lazy(() =>
  import("./kalashproducts/Components/HeaderMain/Header").then((module) => ({
    default: module.Header,
  }))
);

// pages

const HomePage = lazy(() => import("./kalashproducts/Pages/Home"));
const AboutUs = lazy(() => import("./kalashproducts/Pages/AboutUs"));
const Contact = lazy(() => import("./kalashproducts/Pages/ContactUs"));
const Product = lazy(() => import("./kalashproducts/Pages/Product"));
const KalashBrand = lazy(() => import("./kalashproducts/Pages/KalashBrand"));
const Salt = lazy(() => import("./kalashproducts/Pages/Salt"));
const PowderMasala = lazy(() => import("./kalashproducts/Pages/PowderMasala"));
const GotaMasala = lazy(() =>
  import("./kalashproducts/Pages/GotaMasala/GotaMasala")
);
const QualityControl = lazy(() =>
  import("./kalashproducts/Pages/QualityControl")
);
const Uses = lazy(() => import("./kalashproducts/Pages/Uses"));
const UseProcess = lazy(() => import("./kalashproducts/Pages/UseProcess"));

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false,
  });
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener("load", retina(document.querySelectorAll("img")));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      import("./kalashproducts/Functions/Utilities").then((module) => {
        module.SetHeaderMenuPos();
        module.setDocumentFullHeight();
      });
    }, 1000);
  }, [location]);

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            {/* Header Start */}
            <Header topSpace={{ md: false }} type="reverse-scroll">
              <HeaderAlpha />
            </Header>

            {/* Header End */}
            {/* <ScrollToTopButton /> */}
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route
                    path="/"
                    element={<HomePage style={{ "--base-color": "#27ae60" }} />}
                  />
                  <Route path="about-us" element={<AboutUs />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="product" element={<Product />} />
                  <Route path="quality-control" element={<QualityControl />} />
                  <Route path="kalash-brand" element={<KalashBrand />} />
                  <Route path="powder-masala" element={<PowderMasala />} />
                  <Route path="gota-masala" element={<GotaMasala />} />
                  <Route path="salt" element={<Salt />} />
                  <Route path="recipes" element={<Uses />} />
                  <Route path="recipe/:id" element={<UseProcess />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Suspense>
            </AnimatePresence>
            {/* Footer Start */}

            <FooterAlpha />
            {/* Footer End */}
          </main>
        }
      </div>
    </GlobalContext.Provider>
  );
}

export default App;
