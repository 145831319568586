import React, { useState } from "react";

import Header, {
  HeaderNav,
  Menu,
  // SearchBar,
  Topbar,
} from "../../Components/Header/Header";
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import SocialIcons from "../SocialIcon/SocialIcons";

/** Logo & image */
import Logo from "../../../Assets/img/kalashproducts/icons/header-logo.webp";
import LogoWhite from "../../../Assets/img/kalashproducts/icons/header-logo-white.png";
import LogoSm from "../../../Assets/img/kalashproducts/icons/header-logo-sm.png";

const iconData = [
  {
    color: "#828282",
    link: "https://www.facebook.com/",
    icon: "fab fa-facebook-f",
  },
  // {
  //   color: "#828282",
  //   link: "https://dribbble.com/",
  //   icon: "fab fa-dribbble",
  // },
  {
    color: "#828282",
    link: "https://twitter.com/",
    icon: "fab fa-twitter",
  },
  {
    color: "#828282",
    link: "https://www.instagram.com/",
    icon: "fab fa-instagram",
  },
];

const HeaderMain = (props) => {
  const [open, setOpen] = useState(false);
  // const location = useLocation();
  // const currentPath = location.pathname;

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {/* Header Start */}
      <Header
        topSpace={{ md: true }}
        type="reverse-scroll border-b border-[#ffffff1a]"
      >
        <Topbar className="header-with-topbar bg-white border-bottom pl-[35px] pr-[50px] sm:hidden lg:pr-[50px] md:pl-[15px] md:pr-[30px]">
          <Container fluid>
            <Row className="flex items-center justify-between">
              <Col sm="auto">
                <SocialIcons
                  size="xs"
                  theme="social-icon-style-01"
                  className="justify-end sm:justify-center"
                  iconColor="white"
                  data={iconData}
                />
              </Col>
              <Col sm="auto" className="!pr-0">
                <div className="flex text-sm leading-[30px]">
                  <a href="tel:89692290677">
                    <span className="flex items-center border-l py-[9px] px-[18px]">
                      <i className="feather-phone-call mr-[10px] text-md text-darkgray"></i>
                      8969-2290677
                    </span>
                  </a>
                  <span className="flex items-center border-l py-[9px] pl-[18px]">
                    <i className="feather-map-pin mr-[10px] text-md text-darkgray"></i>
                    Main Road, Jugsalai, Beside Chhappan Bhog, Jamshedpur -
                    831006
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </Topbar>
        <HeaderNav
          fluid="fluid"
          theme="dark"
          expand="lg"
          className="py-[5px] px-[35px] lg:pr-[35px] md:px-0 md:py-[7px] "
        >
          <Col className="col-6 col-lg-2 me-auto ps-lg-0 ps-0 ps-md-3">
            <Link
              aria-label="header home link"
              className="flex items-center"
              to="/"
            >
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo "
                  loading="lazy"
                  src={LogoWhite}
                  data-rjs={LogoWhite}
                  alt="logo"
                />
                <img
                  className="alt-logo "
                  loading="lazy"
                  src={Logo}
                  data-rjs={Logo}
                  alt="logo"
                />
                <img
                  className="mobile-logo "
                  loading="lazy"
                  src={LogoSm}
                  data-rjs={LogoSm}
                  alt="logo"
                />
              </Navbar.Brand>
            </Link>
          </Col>
          <Navbar.Toggle
            className={`order-last md:mx-[20px] sm:ml-[15px] sm:mr-0 ${
              !open ? "collapsed" : ""
            }`}
            onClick={handleToggle}
          >
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            className="col-auto menu-order px-lg-0 justify-end"
            in={open}
          >
            <Menu {...props} handleClose={handleClose} />
          </Navbar.Collapse>
          {/* <Col className="col-auto text-end pe-0 pl-[15px] font-size-0">
            <SearchBar />
          </Col> */}
        </HeaderNav>
      </Header>
      {/* Header End */}
    </>
  );
};

export default HeaderMain;
